<template>
	<q-card
		v-if="loading"
		class="q-pa-sm"
	>
		<div class="column">
			<q-skeleton type="QToolbar" />
			<div class="row q-mt-sm q-gutter-x-sm">
				<div
					class="column
						column--notification-center-tabs
						q-gutter-y-sm"
				>
					<q-skeleton
						v-for="num in [1, 2, 3, 4]"
						:key="`skeleton-tab-${num}`"
						height="100px"
					/>
				</div>
				<div
					class="column
						column--notification-center-items
						q-gutter-y-sm"
				>
					<q-skeleton
						v-for="num in [1, 2, 3, 4, 5]"
						:key="`skeleton-item-${num}`"
						height="50px"
					/>
				</div>
			</div>
		</div>
	</q-card>
	<q-card v-else>
		<q-tabs
			v-model="tab"
			dense
			class="text-primary"
			activeColor="active"
			indicatorColor="active"
			align="justify"
		>
			<q-tab name="subscribables" :label="$t('notifications.notificationCenter.email')" />
		</q-tabs>

		<q-separator />

		<q-tab-panels v-model="tab" animated>
			<q-tab-panel name="subscribables" class="q-pa-none">
				<SideMenu notificationsToDisplay="subscribables" />
			</q-tab-panel>
		</q-tab-panels>
	</q-card>
</template>

<script>
	import SideMenu from '@/components/ui/NotificationCenterList';

	export default {
		components: { SideMenu },
		data()
		{
			return {
				tab: 'subscribables',
				splitterModel: 20,
				showEnablePush: false,
				loading: true
			};
		},
		async mounted()
		{
			await this.$store.dispatch('user/notifications/loadUserPreferences');

			this.loading = false;
		}
	};
</script>

<style lang="postcss" scoped>
.column--notification-center {
	&-tabs {
		flex-grow: 1;
	}

	&-items {
		flex-grow: 3;
	}
}
</style>
