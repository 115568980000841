<template>
	<div class="popover">
		<div
			:class="[`activator-${_uid}`]"
			@mouseover="hoverOn"
			@mouseout="hoverOff"
		>
			<slot
				name="activator"
				v-bind="propsForSlot"
			/>
			<q-popup-proxy
				ref="proxy"
				fullWidth
				v-on="$listeners"
				@show="setIsOpen(true)"
				@hide="onHideActions"
			>
				<div
					ref="content"
					:class="contentClasses"
					:style="style"
				>
					<q-card
						:class="cardClasses"
						:style="cardStyles"
					>
						<q-card-section
							:class="[{ borderless }]"
						>
							<slot v-bind="propsForSlot" />
						</q-card-section>
					</q-card>
				</div>
			</q-popup-proxy>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			openOnMouseOver: {
				type: Boolean,
				default: false
			},
			closeOnMouseOff: {
				type: Boolean,
				default: false
			},
			cardClasses: {
				type: String,
				default: null
			},
			cardStyles: {
				type: Object,
				default: () => ({})
			},
			contentClasses: {
				type: String,
				default: 'content'
			},
			contentStyles: {
				type: Object,
				default: () => ({})
			},
			borderless: {
				type: Boolean,
				default: false
			},
			initialWidth: {
				type: Number,
				default: null
			}
		},
		data()
		{
			return {
				isOpen: false,
				calculatedInitialWidth: this.initialWidth
			};
		},
		computed: {
			style()
			{
				return {
					width: `${this.calculatedInitialWidth}px`,
					...this.contentStyles
				};
			},
			propsForSlot()
			{
				return {
					show: this.show,
					hide: this.hide,
					toggle: this.toggle
				};
			}
		},
		beforeDestroy()
		{
			this.$emit('hidden');
		},
		methods: {
			setIsOpen(state)
			{
				this.isOpen = state;

				if(state)
				{
					this.calculatedInitialWidth = this.initialWidth || this.$refs.content.getBoundingClientRect().width;
				}
			},
			show()
			{
				this.$refs.proxy.show();
			},
			onHideActions()
			{
				this.setIsOpen(false);
				this.$emit('hidden');
			},
			hide()
			{
				this.$refs.proxy.hide();
			},
			toggle()
			{
				this.$refs.proxy.toggle();
			},
			hoverOn()
			{
				if(!this.openOnMouseOver) return;

				this.$refs.proxy.show();
			},
			hoverOff()
			{
				if(!this.closeOnMouseOff) return;

				this.$refs.proxy.hide();
			}
		}

	};
</script>

<style scoped>
	>>> .q-card {
		box-shadow: none;
		/* background: transparent; */
		border-radius: 0;
		margin-bottom: 0;
	}

	.borderless {
		padding: 0;
	}

	.popover {
		display: inline-block;
	}
</style>
