import cloneDeep from 'lodash/cloneDeep';
import { get, post, destroy, put } from '@/utils/api';
import { addSuccess, addError } from '@/utils/notifications';

const state = {
	loading: false,
	loadingListener: null,
	loadedAll: false
};

const mutations = {
	LOADING(state, mode)
	{
		state.loading = mode;
	},
	LOADED_ALL(state)
	{
		state.loadedAll = true;
	},
	SET_LOADING_LISTENER(state, listener)
	{
		state.loadingListener = listener;
	}
};

const actions = {
	/**
	 * Load all entities. Note that this returns a separate Promise, which is resolved once the loading is done.
	 * This is done so if there are multiple components using this method at the same time, _and_ they're `await`-ing for it to solve,
	 * they actually need the results from this method and not just skip it due to the `state.loadedAll` check.
	 * One such example is HeadlessSearch's Memberships.vue
	 */
	async loadAll({ dispatch, commit, rootGetters, state }, opts)
	{
		if(!state.loadedAll || opts?.force)
		{
			commit('LOADED_ALL');

			// make sure all callers of this method are notified properly when the request actually finishes
			let resolver = null;

			// set up the listener
			commit('SET_LOADING_LISTENER', new Promise((resolve) =>
			{
				resolver = resolve;
			}));

			dispatch('setLoading', true);

			// do the actual request
			const { data } = await get('entities');

			dispatch('setLoading', false);
			await dispatch('app/handleServerResponse', data, { root: true });

			// when the main part finishes, resolve the promise. This will notify all the `await`-s that entities have been loaded
			resolver();
		}

		// return a single listener to whomever it may concern
		return state.loadingListener;
	},
	setLoading({ commit }, mode)
	{
		commit('LOADING', mode);
	},
	async sendNotification({ dispatch, rootGetters }, { type, definitionId, hookName })
	{
		try
		{
			dispatch('setLoading', true);

			const { usersWhoGotNotification } = await post(`entities/${definitionId}/sendNotification`, { type, hookName });

			dispatch('app/handleServerResponse', usersWhoGotNotification, { root: true });
			addSuccess(`${rootGetters['i18n/get']('admin.dash.entities.success.title')}: ${rootGetters['i18n/get']('admin.dash.entities.success.content')}`);
		}
		catch(e)
		{
			console.error(e);
			addError(`${rootGetters['i18n/get']('admin.dash.entities.failed.title')}: ${rootGetters['i18n/get']('admin.dash.entities.failed.content')}`);
		}

		dispatch('setLoading', false);
	},
	async updateContext({ dispatch, rootGetters }, { id, changes })
	{
		try
		{
			dispatch('setLoading', true);

			const { data } = await post(`entities/${id}/data`, { changes });

			dispatch('app/handleServerResponse', data, { root: true });
			addSuccess(`${rootGetters['i18n/get']('admin.dash.entities.success.title')}: ${rootGetters['i18n/get']('admin.dash.entities.success.content')}`);
		}
		catch(e)
		{
			console.error(e);
			addError(`${rootGetters['i18n/get']('admin.dash.entities.failed.title')}: ${rootGetters['i18n/get']('admin.dash.entities.failed.content')}`);
		}

		dispatch('setLoading', false);
	},
	async updateSettings({ dispatch, rootGetters }, { id, settingsData })
	{
		try
		{
			dispatch('setLoading', true);

			const { data } = await post(`entities/${id}/settingsData`, { changes: settingsData });

			dispatch('app/handleServerResponse', data, { root: true });
			addSuccess(`${rootGetters['i18n/get']('admin.dash.entities.success.title')}: ${rootGetters['i18n/get']('admin.dash.entities.success.content')}`);
		}
		catch(e)
		{
			Object.entries(e.response.data?.errors || {}).forEach(([fieldId, e]) =>
			{
				if(e.filterDuplicatesByKey)
				{
					addError(rootGetters['i18n/dynamicValue']('form.errorTypes.filterDuplicatesByKey', {
						field: rootGetters['i18n/get'](`custom.fields.${fieldId}.label`)
					}));
				}
			});

			console.error(e);
			addError(`${rootGetters['i18n/get']('admin.dash.entities.failed.title')}: ${rootGetters['i18n/get']('admin.dash.entities.failed.content')}`);
		}

		dispatch('setLoading', false);
	},
	async updatePowerUpData({ dispatch, rootGetters }, { id, powerUpData, powerUpId, nested = null, entityDefLevel = false, showSuccess = true })
	{
		try
		{
			dispatch('setLoading', true);

			const { data } = await post(`entities/${id}/${powerUpId}/powerUpData`, { changes: powerUpData[powerUpId], nested, entityDefLevel });

			dispatch('app/handleServerResponse', data, { root: true });

			if(showSuccess) addSuccess(`${rootGetters['i18n/get']('admin.dash.entities.success.title')}: ${rootGetters['i18n/get']('admin.dash.entities.success.content')}`);

			return data;
		}
		catch(e)
		{
			console.error(e, e.response.data?.message);
			addError(`${rootGetters['i18n/get']('admin.dash.entities.failed.title')}: ${rootGetters['i18n/get']('admin.dash.entities.failed.content')}`);

			Object.values(e.response.data?.errors || {}).forEach((e) =>
			{
				if(e.filterDuplicatesByKey)
				{
					addError(rootGetters['i18n/dynamicValue']('form.errorTypes.filterDuplicatesByKey', {
						field: rootGetters['i18n/get'](`powerUps.${powerUpId}.name`)
					}));
				}
			});

			return e.response.data;
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	async addFields({ dispatch }, { entityId, fieldIds })
	{
		dispatch('setLoading', true);

		const { data } = await post(`entities/${entityId}/fields`, { fieldIds });

		if(!data.success)
		{
			console.warn('Error updating entity');
		}
		else
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });
		}

		dispatch('setLoading', false);
	},
	async addField({ dispatch }, { entityId, fieldId })
	{
		dispatch('addFields', { entityId, fieldIds: [fieldId] });
	},
	async removeFields({ dispatch }, { entityId, fieldIds })
	{
		dispatch('setLoading', true);

		const { data } = await destroy(`entities/${entityId}/fields`, { params: { fieldIds } });

		if(!data.success)
		{
			console.warn('Error updating entity');
		}
		else
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });
		}

		dispatch('setLoading', false);
	},
	async removeField({ dispatch }, { entityId, fieldId })
	{
		dispatch('removeFields', { entityId, fieldIds: [fieldId] });
	},
	async createNew({ dispatch }, { definitionId, title })
	{
		dispatch('setLoading', true);

		const { data: response } = await put(`entities/${definitionId}`, { title });

		dispatch('app/handleServerResponse', response.data, { root: true });

		dispatch('setLoading', false);

		return response.id;
	},
	async deleteEntity({ dispatch }, entityId)
	{
		try
		{
			dispatch('setLoading', true);
			await destroy(`entities/${entityId}`);
			dispatch('entities/removeEntity', entityId, { root: true });
		}
		catch(e)
		{
			console.error('Failed to delete entity', e);
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	async updateProperty({ dispatch, rootGetters }, { entityId, key, value })
	{
		// This is for optimistic updates only. If we don't have anything in the store, we can just base it off an empty object.
		const currentData = cloneDeep(rootGetters['entities/byId'](entityId) || {});

		currentData[key] = value;
		dispatch('entities/setEntities', [currentData], { root: true });

		const { data } = await post(`/entities/${entityId}/data/${key}`, { value });

		await dispatch('app/handleServerResponse', data, { root: true });

		return data;
	},
	async updateProperties({ dispatch }, { entityId, data })
	{
		const { data: response } = await post(`/entities/${entityId}/updateKeys`, { data });

		await dispatch('app/handleServerResponse', response, { root: true });

		return response;
	}
};

const getters = {
	loading: (state) => state.loading,
	getFields: (state, getters, rootState, rootGetters) => (entityId) =>
	{
		const entity = rootGetters['entities/byId'](entityId);

		if(!entity)
		{
			return [];
		}

		const fieldIds = entity.fields;

		return fieldIds.map((id) => rootGetters['structure/fields/getField'](id));
	},
	getPowerUpFields: (state, getters, rootState, rootGetters) => (entityId) =>
	{
		const entity = rootGetters['entities/byId'](entityId);

		if(!entity)
		{
			return [];
		}

		return entity.powerUps.map((powerUpId) => rootGetters['powerUps/getFieldsForPowerUp'](powerUpId));
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
