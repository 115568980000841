<template>
	<div
		id="mainContent"
		:class="{mainContent: pageWidth === 'fixed', increasePadding: isAdminEditMode}"
	>
		<h1 id="pagetitle">
			<I18N :id="`custom.pages.${pageId}`" />
		</h1>

		<Container
			v-if="pageNotFound"
			class="fourZeroFourContainer text-center items-center flex"
		>
			<div class="fourZeroFour">
				<img src="@/assets/404-bg.png">
				<I18N
					id="navigation.pages.notFound"
					wrap="h4"
					class="text-bold"
				/>
				<!-- this link goes back to default page -->
				<router-link :to="fourOhFourRedirectLink">
					<SimpleButton
						unelevated
						color="blue-grey"
						size="md"
						padding="sm lg"
						class="text-white"
					>
						<I18N id="navigation.pages.goBack" />
					</SimpleButton>
				</router-link>
			</div>
		</Container>

		<Container v-else-if="!pageLoaded/* && !accountLoaded*/">
			<Spinner color="primary" />
		</Container>

		<section v-else-if="pageLoaded/* && accountLoaded*/ && pageDataLoaded && pageRenderDelayFinished">
			<Block
				v-for="(blockId, index) in pageBlocks"
				:key="`${index}`"
				:slot="0"
				:data="blockId"
				:parentId="pageId"
				colIndex="0"
				:index="index"
			/>
			<!-- @hook:mounted="blockMounted" -->
			<Container>
				<AddBlockButton
					v-if="isAdminEditMode"
					:pageId="pageId"
					:index="pageBlocks.length"
				/>
			</Container>
		</section>

		<Block
			v-if="pageModal"
			v-model="pageModalIsOpen"
			:data="pageModal"
		/>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { addInfo } from '@/utils/notifications';
	import Container from '@/components/layout/Container';
	import { pushRoute } from '@/utils/routerLinkGenerator';
	import { Utils } from 'acb-lib';
	import { cStructureUpdateDelayMs } from '@/configs/constants';
	// import socket from '@/utils/socket';

	export default {
		name: 'Page',
		components: {
			Container,
			Block: () => import('@/components/blocks/Block'),
			AddBlockButton: () => import('@/components/admin/generic/AddBlockButton')
		},
		meta()
		{
			const siteTitle = this.$store.getters['i18n/get']('siteTitle');

			return {
				title: this.pageId ? this.$t(`custom.pages.${this.pageId}`) : siteTitle
			};
		},
		provide()
		{
			this.getData();

			return {
				moduleId: this.module ? this.module.id : null,
				pageId: this.page ? this.page.id : null,
				targetId: this.targetId,
				parentMeta: this.generateParentMeta(),
				parentMetaFn: this.generateParentMeta,
				pageType: this.page ? this.page.type : null,
				i18nVariables: this.generateI18NVariables(),
				parentData: this.pageData,
				reactiveProvidedPageData: this.reactiveProvidedPageData
			};
		},
		data()
		{
			return {
				reactiveProvidedPageData: Vue.observable({}),
				module: {},
				page: {},
				blocksMounted: 0,
				targetId: null,
				pageModalIsOpen: null,
				pageDelayTimeout: null,
				pageRenderDelayFinished: process.env.NODE_ENV === 'test' // We want it always shown when testing
			};
		},
		computed: {
			/** @returns {Boolean} - Whether this page should show a 404 message. */
			pageNotFound()
			{
				return this.$route?.params?.pageSlug === '404' ||
					this.$route?.params?.moduleSlug === '404';
			},
			/**
			 * When the user sees a 404 page, allow them to return to the platform's home page by clicking a link.
			 * If the platform's home page is not found, it falls back to the current user's default profile page.
			 * This could be simpler by using `structure/getDefaultPage`, but that is broken at the moment.
			 * @returns {Object<{name: string, params: { pageSlug: string, moduleSlug: string }}>}
			 */
			fourOhFourRedirectLink()
			{
				const defaultModule = this.$store.getters['structure/modules/getDefaultModule'];

				if(!defaultModule)
				{
					return this.$store.getters['app/settings/defaultProfilePage']();
				}

				const pageId = defaultModule.defaultPage || defaultModule.childIds?.[0];
				const pageSlug = this.$store.getters['structure/pages/getSlugForId'](pageId) || pageId;

				if(pageSlug)
				{
					return {
						name: 'page',
						params: {
							moduleSlug: defaultModule.slug,
							pageSlug
						}
					};
				}

				return '/';
			},
			pageLoaded()
			{
				return this.$store.getters['structure/pages/loaded'](this.page);
			},
			pageDataLoaded()
			{
				// When the page first loads, we need to make sure that we have finished loading all the required data
				return !this.$store.getters['structure/loadingData'];
			},
			entityId()
			{
				return (this.page?.type === 'entity' && this.$store.state.route.params.targetId) || 0;
			},
			// The following methods (`accountToUse` and `accountLoaded`) are commented out as of PT_184440458
			// The `accountLoaded` flag does not distinguish between entity ids and profile ids, and until the logic
			// is updated, we are removing the flag from consideration as it doesn't have enough of a benefit otherwise.
			// See here for more information: https://www.pivotaltracker.com/story/show/184440458
			// accountToUse()
			// {
			// 	return (this.page?.type === 'profile' && this.$store.state.route.params.targetId) || this.$store.state.user.accountId; // this.$store.getters['user/accountId']
			// },
			// accountLoaded()
			// {
			// 	if(this.page?.type === 'profile')
			// 	{
			// 		// Make sure that the account we need to load is loaded. If none specified, that's the current user.
			// 		const accountId = this.accountToUse;
			//
			// 		return this.$store.getters['profiles/accountLoaded'](accountId);
			// 	}
			//
			// 	return true;
			// },
			pageId()
			{
				return this.pageData.id;
			},
			pageModal()
			{
				return this.pageData.relatedBlocks ? this.$store.getters['structure/blocks/getBlock'](this.pageData.relatedBlocks.modal) : undefined;
			},
			userModalData()
			{
				return this.$store.getters['profiles/getDataValueByPath'](this.$store.getters['user/accountId'], `block.${this.pageModal.id}`, 'meta');
			},
			userHasAgreedToModal()
			{
				return this.userModalData && this.userModalData.agreed;
			},
			userHasBeenShownModal()
			{
				return this.userModalData && this.userModalData.shown;
			},
			userShouldSeePageModal()
			{
				if(!this.pageModal || this.pageModal.noAutoOpen) return null;

				return !this.userHasAgreedToModal && // if modal requires agreement it should always be shown until user agrees
					(this.requiresAgreement || !this.modalShowOnce || !this.userHasBeenShownModal); // if no agreement is required, either show every time or if the user hasn't seen the one time modal
			},
			requiresAgreement()
			{
				return this.pageModal.requiresAgreeing;
			},
			modalShowOnce()
			{
				return this.pageModal.showOnce;
			},
			pageData()
			{
				return this.$store.getters['structure/pages/getActivePage'] || {};
			},
			pageBlocks()
			{
				return this.$store.getters['structure/blocks/getActivePageBlockIds'];
			},
			pageWidth()
			{
				return this.pageData.width || 'fixed';
			},
			isAdminEditMode()
			{
				return this.$store.getters['admin/isEditMode'];
			}
		},
		watch: {
			pageModal()
			{
				if(this.pageModal && this.userShouldSeePageModal)
				{
					// register that the user has been shown the modal
					this.registerModalShow();
					this.displayPageModal();
				}
			},
			pageModalIsOpen()
			{
				this.processPageModalUpdate();
			},
			pageModalUserData()
			{
				this.processPageModalUpdate();
			}
		},
		created()
		{
			this.loadPageData();
		},
		async mounted()
		{
			const socket = await import('@/utils/socket').then((mod) => mod.default);

			if(this.page)
			{
				this.$store.dispatch('audit/log', {
					logType: 'pageView',
					subject: this.page.id,
					extra: {
						path: this.$route?.path,
						fullPath: this.$route?.fullPath,
						params: this.$route?.params,
						query: this.$route?.query,
						type: this.page?.type,
						targetId: this.$store.state.route?.params?.targetId
					}
				});

				if(
					this.page.type === 'profile' &&
					this.$store.state.route.params.targetId &&
					(
						this.$store.state.user.accountId !==
						this.$store.state.route.params.targetId
					)
				)
				{
					this.$store.dispatch('audit/logImmediate', {
						logType: 'profileView',
						subject: this.$store.state.route.params.targetId
					});
				}
				else if(
					this.page.type === 'entity' &&
					this.$store.state.route.params.targetId
				)
				{
					// PB: 2024/11/13: do nothing here. Logging's already
					// happening in router/index.js::"/space/:targetId/:moduleSlug/:pageSlug/"::beforeEnter,
					// which more reliably detects that the page we're on is an
					// entity page.
					//
					// this.$store.dispatch('audit/logImmediate', {
					// 	logType: 'entityView',
					// 	subject: this.$store.state.route.params.targetId
					// });
				}
				else if(this.page.type === 'entity' && this.page.id)
				{
					this.$store.dispatch('audit/logImmediate', {
						logType: 'entityPageView',
						subject: this.page.id
					});
				}

				Vue.set(this.reactiveProvidedPageData, 'pageId', this.pageData.id);
			}

			// This delay serves no purpose except making the page transition feel snappier...ironically
			// this.pageDelayTimeout = setTimeout(() =>
			// {
			this.pageRenderDelayFinished = true;
			// }, 100);
			if(this.page)
			{
				socket.emit('page:subscribe', { id: this.page.id });

				socket.on(`page:${this.page.id}:data`, async (data) =>
				{
					// accommodate transitions
					await Utils.sleep(cStructureUpdateDelayMs);
					this.$store.dispatch('structure/pages/setPage', data);
				});
			}

			const el = document.getElementById('extreme_loading');

			if(el)
			{
				el.remove();
			}
		},
		async destroyed()
		{
			const socket = await import('@/utils/socket').then((mod) => mod.default);

			if(this.page)
			{
				socket.emit('page:unsubscribe', { id: this.page.id });

				socket.removeListener(`page:${this.page.id}:data`);
			}

			clearTimeout(this.pageDelayTimeout);
		},
		methods: {
			blockMounted()
			{
				this.blocksMounted += 1;
			},
			processPageModalUpdate()
			{
				if(this.pageModalIsOpen === null) return;

				if(this.pageModalIsOpen === true) return;

				if(this.userShouldSeePageModal)
				{
					if(this.requiresAgreement)
					{
						if(window.history.length === 1) // If we can't redirect the browser (in the app) then just re-open the modal
						{
							this.$nextTick(() =>
							{
								this.pageModalIsOpen = true;
							});

							return;
						}

						window.history.back();
					}
				}
			},
			displayPageModal()
			{
				this.pageModalIsOpen = true;
			},
			async registerModalShow()
			{
				if(!this.userHasBeenShownModal)
				{
					await this.$store.dispatch('profiles/saveProfileMetaData', {
						path: `block.${this.pageModal.id}`,
						data: {
							shown: true,
							timestamp: Date.now()
						}
					});
				}
			},
			getData()
			{
				const { moduleSlug, pageSlug, targetId } = this.$store.state.route.params;

				this.module = this.$store.getters['structure/modules/getModuleBySlug'](moduleSlug);

				if(!this.module)
				{
					this.$router.push('/');

					return;
				}

				this.page = this.$store.getters['structure/pages/getPageBySlug'](moduleSlug, pageSlug);

				this.targetId = targetId || null;
			},
			loadPageData()
			{
				this.getData();

				const { moduleSlug, pageSlug } = this.$store.state.route.params;

				if(!this.module)
				{
					addInfo('That page does not exist in this module, sorry for any inconvenience caused.');

					pushRoute({ params: {
						moduleSlug: '404'
					} }, true);

					return false;
				}

				this.$store.dispatch('app/setActiveModuleId', this.module.id);

				if(!this.page)
				{
					if(!this.module.children || this.module.children.length !== 0)
					{
						addInfo('That page does not exist, sorry for any inconvenience caused.');

						pushRoute({ params: {
							moduleSlug: this.module.slug,
							pageSlug: '404'
						} }, true);
					}

					return false;
				}

				this.$store.dispatch('structure/pages/loadPageContent', this.page);
				this.$store.dispatch('app/setActivePageId', { moduleSlug, pageSlug });

				return true;
			},
			generateParentMeta()
			{
				return {
					type: 'pages',
					id: this.page ? this.page.id : null
				};
			},
			generateI18NVariables()
			{
				const { moduleSlug, pageSlug, targetId } = this.$store.state.route.params;

				return {
					moduleSlug,
					pageSlug,
					targetId
				};
			}
		}
	};
</script>

<style scoped lang="postcss">
	.increasePadding {
		padding-top: 30px;
	}

	>>> .q-page-container {
		background: red !important;
	}

	.fourZeroFourContainer {
		height: 70vh;

		.fourZeroFour {
			width: 100%;

			img {
				max-width: 695px;
			}

			h1 {
				font-size: 90px;
				margin-bottom: 35px;
				font-weight: 600;
			}

			h4 {
				margin-bottom: 30px;
			}
		}
	}
</style>
