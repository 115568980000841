import FormSchemaMixin from '@/components/mixins/FormSchemaMixin';

const UserEditable = {
	inject: {
		targetId: {
			default: null
		}
	},
	props: {
		idToUse: {
			type: [Number, String, undefined],
			default: undefined
		},
		nestedValue: {
			type: String,
			default: ''
		},
		keyToGet: {
			type: String,
			default: ''
		},
		/** @type {Partial<FieldData>} - Data to override the field's properties */
		presetProperties: {
			type: Object,
			default: null
		}
	},
	mixins: [FormSchemaMixin],
	data()
	{
		return {
			fields: []
		};
	},
	computed: {
		getFields()
		{
			return this.fieldDefinitions.filter((field) => field).map((field) =>
			{
				if(field.schema)
				{
					const dataSchema = this.$store.getters['dataSchemas/byId'](field.schema);
					let { value } = field,
						validation = {};

					// if(!dataSchema.source || !dataSchema.key)
					// if(!dataSchema.key)
					// {
					// 	return null;
					// }
					if(dataSchema)
					{
						if(field.getValue !== false)
						{
							value = this.dataSchemaValue(field, dataSchema);
						}

						switch(dataSchema.source)
						{
							case 'entityProperty':
								if(dataSchema.key === 'title')
								{
									validation = { max: 256 };
								}
								else if(dataSchema.key === 'slug')
								{
									validation = { slug_valid: { source: 'entities', id: this.targetId } };
								}

								break;
							default:
								break;
						}
					}

					// oversight on my part when merging the validation property
					// didn't notice that in a spread property merge, the last occurrence wins
					field = {
						...dataSchema,
						...field,
						value,
						...(this.presetProperties?.[field.id] || {}),
						validation: validation && Object.keys(validation).length > 0 ? Object.assign({}, field.validation || {}, validation) : field.validation
					};
				}

				return field;
			}).filter((field) => field);
		}
	},
	mounted()
	{
		if(!this.fieldDefinitions)
		{
			throw new Error('You need to provide a `fieldDefinitions` value, either as a prop or as a computed/data property.');
		}
	}

};

export default UserEditable;
