<template>
	<q-layout
		id="app"
		v-hotkey="keyMap"
		view="hHh lpR fff"
	>
		<!-- top drag bounds for moving blocks -->
		<div
			v-if="enableDragBounds"
			class="dragBound up accel"
			@dragover="dragMotionUpSpeedIncrease"
			@dragleave="dragMotionSpeedReduce"
		/>
		<div
			v-if="enableDragBounds"
			class="dragBound up"
			@dragover="dragBoundUpActivate"
			@dragleave="dragBoundDeactivate"
		/>
		<!-- top drag bounds for moving blocks -->
		<ErrorBoundary><DebugInfo v-if="debugInfo" @close="toggleDebugInfo" /></ErrorBoundary>
		<GlobalEvents
			v-if="keyboard === 'en-GB'"
			@keydown.223="enableTextEditMode"
			@keyup.223="disableTextEditMode"
		/>
		<GlobalEvents
			v-else-if="keyboard === 'en-US'"
			@keydown.192="enableTextEditMode"
			@keyup.192="disableTextEditMode"
		/>
		<portal-target name="app-top" />

		<!-- <FullPageLoader /> -->

		<Spinner style="z-index: 100;" />

		<template v-if="!loggedIn">
			<main>
				<Login />
			</main>
		</template>

		<template v-if="appLoaded">
			<q-footer
				v-if="isSmallScreen"
				reveal
				class="mobile-footer bg-transparent"
			>
				<MobileFooter />
			</q-footer>
			<q-header
				v-if="!headerIsHidden"
				ref="header"
				:reveal="isHeaderRevealEnabled"
				elevated
			>
				<InvisibleNavigationButton anchor="#mainContent" :label="$t('a11y.skipToMainContent')" />
				<AdminBar v-if="isAdmin" @updated="updateHeaderHeight" />
				<ResetViewAs v-if="viewAsIsActive" />
				<TopBar @updated="updateHeaderHeight" />

				<q-banner
					v-if="isOffline"
					inlineActions
					class="text-white bg-red"
				>
					<h4 class="text-center">
						<q-icon
							color="white"
							name="far fa-wifi-slash"
							class="q-mr-md"
						/>
						<I18N id="errors.noInternet" />
					</h4>
				</q-banner>
			</q-header>

			<main>
				<section>
					<q-page-container style="padding-right: 0; padding-bottom: 0">
						<!-- Admin sub-routes are handled in Admin.vue. Revisit this if that changes -->
						<router-view :key="routerKey" />
					</q-page-container>
				</section>
				<aside>
					<AdminPanel v-if="isAdmin" />
				</aside>
				<section>
					<Block
						v-if="!$store.getters['admin/dash/isActive'] && recentBlock"
						:data="recentBlock"
						ignoreVisibility
					/>
				</section>
			</main>

			<q-footer v-if="!footerHidden && !$q.platform.is.mobile" class="footer">
				<FootNote />
			</q-footer>

			<BlockMoveModal
				ref="pasteModal"
				v-model="showBlockMoveModal"
				@paste="paste"
			/>
			<CapturedPagesList />
		</template>

		<section>
			<portal-target name="app-bottom" />
		</section>

		<Modal
			v-if="socialSignInEnabled"
			ref="manageMyLogins"
			v-model="showSocialSignInModal"
			width="1000px"
			persistent
			:customClasses="['ssiMyLoginsModal']"
		>
			<div class="row">
				<div class="col-xs-12 col-md-5">
					<I18N
						id="userControls.manageMyLogins.titles.main"
						wrap="h2"
						class="q-mb-md"
					/>
					<div class="flex">
						<q-badge
							rounded
							size="md"
							:label="linkedSSIAccountsCount"
							class="ssiCount q-mr-md"
						/>
						<I18N
							id="userControls.manageMyLogins.descriptions.ssiCount"
							wrap="p"
							:plural="linkedSSIAccountsCount"
							:class="$q.dark.isActive ? 'text-grey-5' : 'text-grey-9'"
						/>
					</div>
				</div>
				<div class="col-xs-12 col-md-7 ssi-modal-description">
					<I18N
						id="userControls.manageMyLogins.descriptions.modal"
						wrap="p"
					/>
				</div>
			</div>
			<SocialAccountLinks
				requestSource="manageModal"
			/>
		</Modal>
		<!-- bottom drag bounds for moving blocks -->
		<div
			v-if="enableDragBounds"
			class="dragBound down"
			@dragover="dragBoundDownActivate"
			@dragleave="dragBoundDeactivate"
		/>
		<div
			v-if="enableDragBounds"
			class="dragBound down accel"
			@dragover="dragMotionDownSpeedIncrease"
			@dragleave="dragMotionSpeedReduce"
		/>
		<!-- bottom drag bounds for moving blocks -->

		<AppInstallModal
			v-if="isAppInstallableAndroid || isAppInstallableApple || isAppInstalled"
			ref="appInstallModal"
			:promptEvent="promptEvent"
			preventClosing
			persistent
			:isAndroid="isAppInstallableAndroid"
			:isApple="isAppInstallableApple"
		/>
		<GlobalChatRoomModal v-if="appLoaded" />
		<portal-target name="lightroom-image-uploader" multiple />
	</q-layout>
</template>

<script>
	import GlobalEvents from 'vue-global-events';
	import throttle from 'lodash/throttle';
	import debounce from 'lodash/debounce';
	import WebFontLoader from 'webfontloader';
	import TopBar from '@/components/layout/TopBar';
	import Spinner from '@/components/layout/Spinner';
	import FootNote from '@/components/layout/FootNote';
	import Login from '@/components/layout/Login';
	import InvisibleNavigationButton from
		'@/components/ui/InvisibleNavigationButton';
	import {
		userCan,
		userCanAnyOf,
		userCanAnyOfActions
	} from '@/plugins/Permissions';
	import CapturedPagesList from '@/components/ui/CapturedPagesList';
	import ErrorBoundary from '@/components/ui/ErrorBoundary';
	import EventBus from '@/components/admin/generic/EventBus';
	import { appShortKeys } from '@/configs/constants';
	import BlockMoveModal from '@/components/ui/BlockMoveModal.vue';
	import {
		addUserDeviceKeepaliveInterval,
		removeUserDeviceKeepaliveInterval
	} from '@/utils/userDeviceKeepalive';
	import { cLanguageIsosFontsRequired } from '@/configs/constants/languages';

	async function handleNetworkChange(store)
	{
		const timestamp = Date.now();

		try
		{
			await fetch(`https://www.google.com?random=${timestamp}`, { mode: 'no-cors' });

			store.dispatch('app/setOffline', false);
		}
		catch
		{
			store.dispatch('app/setOffline', true);
		}
	}

	export default {
		components: {
			BlockMoveModal,
			TopBar,
			Spinner,
			FootNote,
			Login,
			GlobalEvents,
			InvisibleNavigationButton,
			CapturedPagesList,
			ErrorBoundary,
			AdminPanel: () => import(/* webpackChunkName: "admin" */ '@/components/admin/panel/AdminPanel'),
			Block: () => import('@/components/blocks/Block'),
			AdminBar: () => import(/* webpackChunkName: "admin" */ '@/components/layout/AdminBar'),
			ResetViewAs: () => import('@/components/layout/ResetViewAs'),
			Modal: () => import('@/components/Modal'),
			SocialAccountLinks: () => import('@/components/profile/SocialAccountLinks'),
			DebugInfo: () => import('@/components/ui/DebugInfo'),
			MobileFooter: () => import('@/components/ui/MobileFooter'),
			AppInstallModal: () => import('@/components/ui/mobileApp/AppInstallModal'),
			GlobalChatRoomModal: () => import('@/components/chat/video/GlobalChatRoomModal.vue')
		},
		data: () => ({
			tab: '',
			active: false,
			drawerModules: false,
			drawerPages: false,
			loadedTimeout: null,
			enableDragBounds: false,
			dragMovement: null,
			dragMotionSpeed: 2,
			dragMotionUpdateInterval: 10,
			promptEvent: null,
			isAppInstallableAndroid: false,
			isAppInstallableApple: false,
			isAppInstalled: false,
			isHeaderRevealEnabled: true
		}),
		computed: {
			debugInfo()
			{
				return this.$store.getters['admin/isDebugMode'];
			},
			footerHidden()
			{
				// Hide footer in admin dash
				if(this.$store.getters['admin/dash/isActive'])
				{
					return true;
				}

				return this.$store.getters['app/footerHidden'];
			},
			keyboard()
			{
				// note that this is just browser language, not the actual keyboard layout used
				return navigator && navigator.language;
			},
			isAdmin()
			{
				return userCanAnyOf({ administration: ['managePermissions', 'allowViewAs', 'manageEditMode', 'manageTextEdit', 'showAdminNotifications'] }) ||
					userCanAnyOfActions(['viewDashboardPage', 'managePermissions'], true);
			},
			appLoaded()
			{
				return this.$store.getters['app/appLoaded'];
			},
			// appLoading()
			// {
			// 	return this.$store.getters['app/loading'];
			// },
			// appLoadedAndNoPendingRequests()
			// {
			// 	return this.appLoaded && !this.appLoading;
			// },
			loggedIn()
			{
				return this.$store.getters['user/loggedIn'];
			},
			keyMap()
			{
				return {
					[appShortKeys.editMode]: this.toggleAdminEditMode,
					[appShortKeys.debugInfo]: this.toggleDebugInfo,
					[appShortKeys.textEditMode]: this.toggleTextEditMode,
					[appShortKeys.safeMode]: this.toggleSafeMode
				};
			},
			headerIsHidden()
			{
				return this.$store.getters['admin/dash/isActive'];
			},
			recentBlock()
			{
				return this.$store.getters['structure/blocks/getGlobalBlock']('recentFooter');
			},
			/**
			 * @returns {number} Number of SSI accounts linked for current user.
			 */
			linkedSSIAccountsCount()
			{
				const links = this.$store.getters['user/getProfile']?.socialAccountLinks ?? [];

				return links.length;
			},
			connectedInstance()
			{
				return this.$store.getters['app/connectedInstance'];
			},
			revertToken()
			{
				return this.$store.getters['structure/admin/revertToken'];
			},
			themeModeEnabled()
			{
				return this.$store.getters['app/settings/get']('themeMode')?.enabled;
			},
			appTheme()
			{
				return this.$store.getters['user/getMetaSimple']?.settings?.theme;
			},
			permissions()
			{
				return this.$store.getters['user/permissions'];
			},
			socialSignInEnabled: {
				get()
				{
					return this.$store.getters['app/settings/get']('socialSignIn')?.enable === 'enable';
				}
			},
			registeredWithSSO: {
				get()
				{
					return this.$store.getters['user/frameworkData']('accountCreationType')?.includes('sso');
				}
			},
			showSocialSignInModal: {
				get()
				{
					return this.$store.getters['app/showSocialSignInModal'];
				},
				set(show)
				{
					if(!show)
					{
						this.$store.dispatch('app/resetSocialSignInStatus');
					}

					this.$store.dispatch('app/setShowSocialSignInModal', show);
				}
			},
			showBlockMoveModal: {
				get()
				{
					// when this refreshes, then something has changed
					// if the `showModal` is set to `true`, open the modal
					if(this.$refs?.pasteModal && this.blockMoveModalId && this.$store.getters['app/showBlockMoveModal'])
					{
						this.$refs.pasteModal.open(this.$store.getters['app/blockMoveModalData']);
					}

					return this.$store.getters['app/showBlockMoveModal'];
				},
				set()
				{
					this.$store.dispatch('app/setShowBlockMoveModal', { showModal: false, id: null });
				}
			},
			blockMoveModalId()
			{
				return this.$store.getters['app/blockMoveModalId'];
			},
			helpdeskLink: {
				get()
				{
					return `${window.location.origin}/content/help/contact.php`;
				}
			},
			isOffline()
			{
				return this.$store.getters['app/isOffline'];
			},
			bodyClasses()
			{
				if(this?.$q?.platform?.is && typeof this.$q.platform.is === 'object')
				{
					return {
						...this.$q.platform.is
					};
				}

				return {};
			},
			routerKey()
			{
				return this.$route?.matched?.some((route) => (route?.meta?.adminPage === true)) ? 'isAdminRoute' : this.$route.path;
			},
			fontSettings()
			{
				return this.$store.getters['app/settings/get']('font');
			},
			fontUrl()
			{
				const url = this.fontSettings?.url;

				return typeof url === 'string' ? [url] : url;
			},
			fontFamily()
			{
				const family = this.fontSettings?.family;

				return typeof family === 'string' ? [family] : family;
			},
			viewAsIsActive()
			{
				return !!this.$store.getters['admin/viewingAs']?.length;
			},
			headerScrollToTopKey()
			{
				return this.$store.getters['app/headerScrollToTopKey'];
			},
			localeActiveIso()
			{
				return this.$store.getters['i18n/localeActive'];
			},
			isSmallScreen()
			{
				return this.$q.screen.lt.md;
			}
		},
		watch: {
			localeActiveIso()
			{
				if(!cLanguageIsosFontsRequired.includes(this.localeActiveIso))
				{
					document.body.style.fontFamily = 'var(--body-font-font-family)';

					return;
				}

				document.body.style.fontFamily = `var(--body-font-font-family-${this.localeActiveIso})`;
			},
			appLoaded(newVal)
			{
				if(newVal)
				{
					this.$store.dispatch('app/hideFullLoader');

					addUserDeviceKeepaliveInterval();
				}

				// App is not installed and we can install it so wait 3 sec and show the modal
				if((this.isAppInstallableAndroid || this.isAppInstallableApple) && !this.isAppInstalled)
				{
					// Timeout to show it properly after the page is loaded
					setTimeout(() =>
					{
						this.$refs.appInstallModal.triggerAppInstall();
					}, 3000);
				}
			},
			permissions: {
				handler(val)
				{
					if(val)
					{
						this.$ability.update(val);
					}
				},
				deep: true
			},
			revertToken(newVal)
			{
				if(newVal)
				{
					this.$q.notify({
						message: this.$t('revertChanges.notification.title'),
						color: 'primary',
						position: 'top',
						timeout: 30000,
						progress: true,
						actions: [
							{
								label: this.$t('revertChanges.notification.action'),
								color: 'negative',
								handler: () =>
								{
									this.$q.dialog({
										title: this.$t('revertChanges.dialog.title'),
										message: this.$t('revertChanges.dialog.message'),
										cancel: true,
										persistent: true
									})
										.onOk(() =>
										{
											this.$store.dispatch('structure/admin/executeRevert');
										})
										.onCancel(() =>
										{
											//
										});
								}
							},
							{
								label: this.$t('revertChanges.notification.dismiss'),
								color: 'white'
							}
						]
					});
				}
			},
			appTheme(val)
			{
				this.updateThemeToken();
				this.$store.dispatch('app/settings/setTheme', val);
			},
			themeModeEnabled(enabled)
			{
				this.updateThemeToken();
				this.$store.dispatch('app/settings/toggleThemeModeEnabled', { force: enabled });
			},
			fontSettings()
			{
				this.loadFonts();
			},
			/**
			 * The header can be a bit dim,
			 * when it's hidden and the page changes.
			 * It doesn't know that it should re-appear for certain scenarios,
			 * e.g. a modal is open, overriding any scroll overflow,
			 * and routing tries to scroll to top.
			 * No scrolling actually happens,
			 * so the header remains hidden.
			 *
			 * This forces appearance.
			 * Use the store dispatch 'app/scrollToTop', to trigger this.
			 */
			async headerScrollToTopKey()
			{
				this.isHeaderRevealEnabled = false;

				await this.$nextTick();

				this.isHeaderRevealEnabled = true;
			},
			async isAppInstalled(isInstalled)
			{
				// We got an app! Double check if we still allow apps
				if(isInstalled)
				{
					setTimeout(() =>
					{
						this.$refs.appInstallModal.showAppDisabled();
					}, 3000);
				}
			}
		},
		meta()
		{
			const favicon = this.$store.getters['app/settings/get']('faviconImage');

			const siteTitle = this.$store.getters['i18n/get']('siteTitle');

			const meta = {
				title: siteTitle,
				titleTemplate: (title) => `${title} - ${siteTitle}`
			};

			if(favicon)
			{
				meta.link = [
					{ rel: 'icon', href: this.$imagePath(favicon) }
				];
			}

			return meta;
		},
		provide()
		{
			return {
				parentMeta: {
					type: 'modules',
					id: 'global'
				}
			};
		},
		created()
		{
			// Push add back in
			// this.$messaging.onMessage((payload) =>
			// {
			// 	// todo decide what to do with our data - called when a) app has focus - maybe redundant because the toast notification alerts the user in app of new notification
			// });

			this.$store.dispatch('app/showFullLoader');

			// setTimeout(() =>
			// {
			// 	clearInterval(this.loadedTimeout);
			// 	this.$store.dispatch('app/hideFullLoader');
			// }, 2000);

			// Capture event and defer
			window.addEventListener('beforeinstallprompt', this.addPromptEvent);

			this.getAppInstalled();

			setInterval(() =>
			{
				this.$store.dispatch('audit/save');
			}, 5000);

			if(window.addEventListener)
			{
				window.addEventListener('beforeunload', this.beforeUnloadHandler);
			}
			else
			{
				window.attachEvent('onbeforeunload', this.beforeUnloadHandler);
			}
		},
		mounted()
		{
			this.setBodyPlatformClasses();
			window.addEventListener('online', () => handleNetworkChange(this.$store));

			window.addEventListener('offline', () => handleNetworkChange(this.$store));

			this.updateHeaderHeight();

			// Prepare for block dragging
			EventBus.$on('structure:blocks:dragbounds:activate', debounce(this.activateDragBounds, 200));
			EventBus.$on('structure:blocks:dragbounds:deactivate', this.deactivateDragBounds);
		},
		destroyed()
		{
			window.removeEventListener('online', () => handleNetworkChange(this.$store));
			window.removeEventListener('offline', () => handleNetworkChange(this.$store));

			document.removeEventListener('click', this.onAnyClick);

			EventBus.$off('structure:blocks:dragbounds:activate');
			EventBus.$off('structure:blocks:dragbounds:deactivate');

			removeUserDeviceKeepaliveInterval();
		},
		methods: {
			getAppInstalled()
			{
				// TODO
				// This suppose to have more related information about the installed app
				// const relatedApps = await navigator.getInstalledRelatedApps();

				if(this.$q.platform.is.ios || this.$q.platform.is.iphone || this.$q.platform.is.ipad)
				{
					this.isAppInstallableApple = true;
				}

				// Check for android
				if(window?.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
				{
					this.isAppInstalled = true;
				}
				// Check for iOS
				else if(window?.navigator && window.navigator.standalone === true)
				{
					this.isAppInstalled = true;
				}
			},
			addPromptEvent(promptEvent)
			{
				promptEvent.preventDefault();
				this.promptEvent = promptEvent;
				// Currently we only detect android
				// promptEvent can be used to install as an app for other devices (eg windows) if needed
				if(this.$q.platform.is.android)
				{
					this.isAppInstallableAndroid = true;
				}
			},
			keyMonitorDown(event)
			{
				if(this.active) return;

				this.active = true;
				const keyPressed = (event.shiftKey ? 'Shift+' : '') + event.key || String.fromCharCode(event.keyCode);

				this.$store.dispatch('app/keyHeldDown', keyPressed);
			},
			keyMonitorUp()
			{
				this.active = false;
				this.$store.dispatch('app/keyHeldDown', null);
			},
			toggleTextEditMode: throttle(function toggleTextEditMode()
			{
				if(this.$store.getters['i18n/admin/editMode'])
				{
					this.disableTextEditMode();

					return;
				}

				this.enableTextEditMode();
			}),
			toggleSafeMode()
			{
				const currentSafeMode = this.$store.getters['admin/isSafeMode'];

				this.$store.dispatch('admin/setSafeMode', !currentSafeMode);
			},
			enableTextEditMode()
			{
				const current = this.$store.getters['i18n/admin/editMode'];

				if(userCan('manageTextEdit', 'administration') && !current)
				{
					this.$store.dispatch('i18n/admin/setEditMode', true);
				}
			},
			disableTextEditMode()
			{
				this.$store.dispatch('i18n/admin/setEditMode', false);
			},
			toggleAdminEditMode: throttle(function toggleAdminEditMode()
			{
				// This prevents the event being triggered while the user holds the key combination down! DO NOT REMOVE OR ELSE YOU BRING LAG TO THIS WORLD! Maybe it can be brought down to 500ms though :) ^2u tehe
				if(userCan('manageEditMode', 'administration'))
				{
					this.$store.dispatch('admin/setEditMode', !this.$store.getters['admin/isEditMode']);
				}
			}, 1000),
			toggleDebugInfo: throttle(function toggleAdminEditMode()
			{
				if(this.isAdmin)
				{
					this.$store.dispatch('admin/toggleDebugMode');
				}
			}, 1000),
			beforeUnloadHandler()
			{
				this.$store.dispatch('audit/save');
			},
			updateThemeToken()
			{
				// since we're storing the admin configured themeModeEnabled, don't want to leave this as plain text
				// changes manually made by a user would get overwritten once the settings load, so it's just discouragement
				const themeModeToken = Buffer.from(JSON.stringify({ theme: this.appTheme, themeModeEnabled: this.themeModeEnabled })).toString('base64');

				localStorage.setItem('themeModeToken', themeModeToken);
			},
			setBodyPlatformClasses()
			{
				if(this.bodyClasses && Object.keys(this.bodyClasses).length > 0)
				{
					const keys = Object.keys(this.bodyClasses)
						.map((key) =>
						{
							if(typeof this.bodyClasses[key] === 'boolean') return key;

							return null;
						})
						.filter((k) => k)
						.map((key) => `platform-${key}`);

					const body = document.querySelector('body');

					if(body)
					{
						body.classList.add(...keys);
					}
				}
			},
			getHeaderHeight()
			{
				return this.headerIsHidden ? -1 : this.$refs?.header?.$el?.clientHeight || -1;
			},
			updateHeaderHeight()
			{
				this.$store.dispatch('app/settings/setHeaderHeight', this.getHeaderHeight());
			},
			loadFonts()
			{
				WebFontLoader.load({
					custom: {
						families: this.fontFamily,
						// Path to stylesheet that defines font-face
						urls: this.fontUrl
					}
				});
			},
			dragBoundUpActivate(e)
			{
				if(!this.dragMovement)
				{
					this.dragMovement = setInterval(() =>
					{
						window.scrollBy(0, -(this.dragMotionSpeed));
					}, this.dragMotionUpdateInterval);
				}
			},
			dragBoundDownActivate(e)
			{
				if(!this.dragMovement)
				{
					this.dragMovement = setInterval(() =>
					{
						window.scrollBy(0, this.dragMotionSpeed);
					}, this.dragMotionUpdateInterval);
				}
			},
			dragBoundDeactivate(e)
			{
				clearInterval(this.dragMovement);

				this.dragMovement = null;
			},
			dragMotionUpSpeedIncrease()
			{
				this.dragMotionSpeed = 6;
				this.dragBoundUpActivate();
			},
			dragMotionDownSpeedIncrease()
			{
				this.dragMotionSpeed = 6;
				this.dragBoundDownActivate();
			},
			dragMotionSpeedReduce()
			{
				this.dragMotionSpeed = 2;
				this.dragBoundDeactivate();
			},
			activateDragBounds()
			{
				this.enableDragBounds = true;
			},
			deactivateDragBounds()
			{
				this.enableDragBounds = false;
			},
			paste()
			{
				this.$refs.pasteModal.close();
			}
		}
	};
</script>

<style lang="postcss">
	:root {
		--admin-panel-tabs-bar-width: 60px;
		--admin-panel-width: 35vw;
		/**
		This ensures the page's scrollbar doesn't overlap
		with the panel's scrollbar
		*/
		--admin-panel-scrollbar-offset: 10px;
		--admin-panel-width-adminEditInsightsReport-max: 500px;
		--admin-panel-padding: 20px;
		--q-td-inline-padding: 8px;
		--admin-panel-toolbar-height: 70px;
		--roundCorners-all-quasar: 4px;
		--q-margin-unit: 8px;
		--admin-toolbar-height: 50px;
		--admin-dashboard-padding-top: 25px;
		--q-dialog-inline-padding: 16px;
		--admin-panel-drawer-bg-color: #272a35;
		--admin-panel-contents-height: calc(100vh - 2 * var(--admin-panel-toolbar-height));
		--admin-panel-padded-contents-height: calc(var(--admin-panel-contents-height) - 2 * var(--admin-panel-padding));

		@media screen and (max-width: 992px) {
			--admin-panel-width: 50vw;
		}

		@media screen and (max-width: 768px) {
			--admin-panel-width: 100vw;
		}
	}

	html {
		font-size: var(--html-font-size);
	}

	body {
		margin: 0;
		font-family: var(--body-font-font-family);
		font-weight: var(--body-font-font-weight);
		background: var(--body-color-background);
		font-size: var(--body-font-size);
		line-height: var(--body-font-line-height);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0.17rem 0 0.47rem 0;
		font-family: var(--headings-font-family);
	}

	h1 {
		font-size: var(--headings-h1-size);
		line-height: var(--headings-h1-line-height);
		font-weight: var(--headings-h1-font-weight);
		letter-spacing: var(--headings-h1-letter-spacing);
	}

	h2 {
		font-size: var(--headings-h2-size);
		line-height: var(--headings-h2-line-height);
		font-weight: var(--headings-h2-font-weight);
		letter-spacing: var(--headings-h2-letter-spacing);
	}

	h3 {
		font-size: var(--headings-h3-size);
		line-height: var(--headings-h3-line-height);
		font-weight: var(--headings-h3-font-weight);
		letter-spacing: var(--headings-h3--letter-spacing);
	}

	h4 {
		font-size: var(--headings-h4-size); /* 1.1rem */
		line-height: var(--headings-h4-line-height); /* 1.5rem */
		font-weight: var(--headings-h4-font-weight);
		letter-spacing: var(--headings-h4-letter-spacing);

		&.small {
			font-size: calc(var(--headings-h4-size) - 0.1);
			line-height: calc(var(--headings-h4-line-height) - 0.1);
		}
	}

	h5 {
		font-size: var(--headings-h5-size);
		line-height: var(--headings-h5-line-height);
		font-weight: var(--headings-h5-font-weight);
		letter-spacing: var(--headings-h5-letter-spacing);
	}

	h6 {
		font-size: var(--headings-h6-size);
		line-height: var(--headings-h6-line-height);
		font-weight: var(--headings-h6-font-weight);
		letter-spacing: var(--headings-h6-letter-spacing);
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: var(--body-link-linkColor);
		text-decoration: var(--body-link-textDecoration);
		overflow-wrap: break-word;
		word-wrap: break-word;
	}

	a:hover,
	a:focus {
		transition: 0.3s;
		opacity: 0.7;
		text-decoration: var(--body-link-hover-textDecoration);
		color: var(--body-link-hover-linkColor);
	}

	img {
		width: 100%;
	}

	.emailTemplateBuilder .previewContainer img {
		width: auto;
	}

	.italic {
		font-style: italic;
	}

	.bold {
		font-weight: 600;
	}

	.cursorPointer {
		cursor: pointer;
	}

	.cursorGrab {
		cursor: grab;
	}

	.q-header {
		background: var(--palette-grey-1);
		border-bottom: 1px solid hsl(0, 0%, 100%, 0.1);
	}

	body.body--dark .q-header {
		border-bottom: 1px solid hsl(0, 0%, 0%, 0.7);
	}

	.header {
		background: var(--header-color-background);
	}

	.adminPanelStyle {
		overflow-y: auto;
		overflow-x: hidden;
		padding: var(--admin-panel-padding);
		max-height: var(--admin-panel-contents-height);
		min-height: auto !important;
		width: 100%;

		&.with-drawer {
			width: calc(100% - var(--admin-panel-tabs-bar-width));
		}
	}

	/* margin: 0;
	padding: 20px;
	max-width: 100%;
	width: 100%; */

	/** Used when there's also a tabs bar. */
	.admin-panel-page-container {
		/** Important isn't good, but quasar applies padding at the element level. */
		padding: 0 !important;
		width: calc(var(--admin-panel-width) - var(--admin-panel-tabs-bar-width) - var(--admin-panel-padding) * 2);
	}

	.footer {
		background: var(--footer-color-background);
	}

	.hero {
		box-shadow: var(--shadow-element-dreamy-soft);

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			color: var(--colors-white);
		}
	}

	.q-btn {
		text-transform: none;
	}

	.divider {
		border-bottom: 1px dashed #bbb;
		margin: 18px 0;
	}

	.verticalSeparator {
		line-height: 1em;
	}

	.form-data {
		padding: 0 16px;

		p {
			margin: 0;

			&.form-content {
				margin: 10px 0 0 0;
			}
		}
	}

	.defaultDarkModal {
		background: #1b1d25;
	}

	.defaultDarkModalTool {
		background: var(--admin-panel-drawer-bg-color);
	}

	.textTranspBg {
		border-radius: var(--roundCorners-all-xxl);
		display: inline-block;
		padding: 6px 16px;

		&.whiteTransp {
			background: var(--background-transparency-thirtyWhite);
		}

		&.blackTransp {
			background: var(--background-transparency-thirtyBlack);
		}
	}

	.q-field--disabled {
		.q-field__control {
			background: rgba(0, 0, 0, 0.05);
		}
	}

	.admin-panel {
		.q-field__control {
			color: #f2c037;
		}
	}

	.textSmTranspBg {
		border-radius: var(--roundCorners-all-xxl);
		display: inline-block;
		padding: 3px 8px;

		&.blackTransp {
			background: var(--background-transparency-tenBlack);
		}
	}

	.recentlyViewedGrid {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;

		li {
			flex: calc(100% / 3);
			text-align: center;
			list-style: none;

			a {
				border-radius: 5px;
				height: 80px;
				width: 80px;
				background-size: cover;
				display: inline-block;

				&:hover,
				&:focus {
					opacity: 0.6;
				}
			}

			h5 {
				margin: 0;
			}
		}
	}

	.q-uploader {
		background: rgba(239, 239, 239, 0.9);

		&::before {
			background: url('~@/assets/upload-icon.png');
			width: 110px;
			height: 60px;
			content: '';
			position: absolute;
			top: 60%;
			margin-top: -30px;
			left: 50%;
			margin-left: -55px;
		}

		.q-uploader__header {
			background: transparent;

			& i {
				color: var(--q-color-primary);
			}

			.q-uploader__title,
			.q-uploader__subtitle {
				color: #444;
			}
		}
	}

	.q-uploader--bordered {
		border: 1.4px dashed rgba(0, 0, 0, 0.3);
		border-radius: var(--roundCorners-all-sm);
	}

	/* Extending quasar ellipsis helper classes */
	.ellipsis-1-line,
	.ellipsis-4-lines,
	.ellipsis-5-lines,
	.ellipsis-6-lines,
	.ellipsis-7-lines,
	.ellipsis-8-lines,
	.ellipsis-9-lines {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.ellipsis-1-line {
		-webkit-line-clamp: 1;
	}

	.ellipsis-4-lines {
		-webkit-line-clamp: 4;
	}

	.ellipsis-5-lines {
		-webkit-line-clamp: 5;
	}

	.ellipsis-6-lines {
		-webkit-line-clamp: 6;
	}

	.ellipsis-7-lines {
		-webkit-line-clamp: 7;
	}

	.ellipsis-8-lines {
		-webkit-line-clamp: 8;
	}

	.ellipsis-9-lines {
		-webkit-line-clamp: 9;
	}

	.heightInherit {
		height: inherit;
	}

	.socialContainer {
		margin-bottom: 15px;
		background: #efefef;
		padding: 10px 20px;
		border-radius: 5px;
		width: 100%;
		color: #fff;
		font-weight: 600;

		&.instagram {
			background: #993c6e;
		}

		&.twitter {
			background: #1ea1f2;
		}

		&.facebook {
			background: #4f579b;
		}

		&.linkedin {
			background: #5b76b4;
		}

		&:hover,
		&:focus {
			opacity: 0.5;
			color: #fff;
			animation: 3s;
		}
	}

	.custom-shadow {
		box-shadow: var(--shadow-element-dreamy-strong);
	}

	.basic-text-shadow {
		text-shadow: var(--shadow-text-basic);
	}

	.icon-color {
		color: var(--icons-color-default);
	}

	.q-card {
		border-radius: var(--roundCorners-all-md);
		margin-bottom: 16px;

		.q-img {
			background-position: center center;
			background-size: cover;
			background-repeat: unset;
		}

		&.q-card--dark {
			background-color: var(--q-color-dark);

			.q-card {
				background-color: var(--q-color-dark);
			}
		}

		&.list {
			.q-img {
				/* height: 60px; */
				border-radius: var(--roundCorners-all-md);
			}
		}

		&.cardHoverEffect {
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				z-index: -1;
			}

			&:hover,
			&:focus {
				transform: scale(1.05, 1.05);
				box-shadow: var(--shadow-element-dreamy-soft);
				cursor: pointer;
			}
		}

		&.cardUserHoverEffect {
			overflow: hidden;

			&:hover,
			&:focus {
				transform: scale(1.05, 1.05);
				box-shadow: var(--shadow-element-dreamy-soft);
				cursor: pointer;

				.avatar-img {
					transform: scale(1.05, 1.05);
					transition: all 0.3s;
				}
			}
		}
	}

	.modules-nav {
		.q-tabs--horizontal {
			.q-tabs__arrow--left {
				left: -20px;
				font-size: 20px;
			}

			.q-tabs__arrow--end {
				right: -20px;
				font-size: 20px;
			}

			.q-tabs__arrow--right {
				right: -20px;
				font-size: 20px;
			}

			.q-tabs__arrow--start {
				left: -20px;
				font-size: 20px;
			}
		}
	}

	.cardContentRoundTop {
		border-radius: var(--roundCorners-top-md) !important;
		margin-top: -14px;
		background: #fff;
		display: block;
		height: 14px;
		position: relative;
		z-index: 0;
	}

	.q-tab {
		text-transform: none;
	}

	.q-tab__indicator {
		background: var(--button-default);
	}

	.q-table__top {
		top: 0;
		position: sticky;
		opacity: 1;
		z-index: 1;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}

	.admin .q-table__top {
		background: #fff;
	}

	.q-table thead {
		background-color: #fff;
		top: 0;
		position: sticky;
		opacity: 1;
		z-index: 1;
		box-shadow: var(--shadow-element-dreamy-extraSoft);
	}

	.q-table--dark thead {
		background-color: var(--q-color-dark);
	}

	.admin .q-table thead {
		background: #fff;
	}

	.q-table__middle {
		max-height: calc(100vh - 370px);
		min-height: 150px;
		padding-bottom: 30px;
	}

	.admin-panel .q-table__middle {
		height: auto;
		max-height: revert;
		padding-bottom: revert;
	}

	.dashboard {
		.q-table__middle {
			min-height: 150px;
			max-height: 250px;
		}
	}

	.q-table__container.fullscreen {
		.q-table__middle {
			/** Fullscreen table body can take up the full window height. */
			max-height: unset;
		}
	}

	.q-table__bottom {
		bottom: 0;
		position: sticky;
		opacity: 1;
		z-index: 1;
		box-shadow: var(--shadow-element-dreamy-extraSoftUp);
	}

	.admin .q-table__bottom {
		background: #fff;
	}

	/* stylelint-disable */
	.q-table tbody td {
		font-size: 0.9em;
		height: 32px;
	}

	.q-table {
		th {
			font-weight: 600;
			font-size: 1em;
			padding: 6px 8px;
			border-right: 1px solid rgba(0, 0, 0, 0.12);

			.q-field__control,
			.q-field__marginal {
				height: 25px;
				min-height: 25px;
			}

			& th {
				padding: 0 0 5px 0;
				width: 100%;
				display: inline-block;
				border: 0;
				text-align: left;
			}

			&:last-child {
				border-right: 0;
			}

			&.sortable {
				/* border: 0; */
			}
		}

		td {
			padding: 0 8px;
			border-right: 1px solid rgba(0, 0, 0, 0.12);

			&:last-child {
				border-right: 0;
			}

			.q-btn__wrapper {
				min-height: auto;
			}
		}
	}

	.q-table tr {
		&:nth-child(2n) {
			background: #f1f1f178;
		}
	}

	/*
	Lower opacity than the default - this is the "not
	currently sorting" state, it needs to be clear
	*/
	.q-table th.sortable:hover .q-table__sort-icon {
		opacity: 0.30;
	}

	.q-table th.sorted .q-table__sort-icon {
		opacity: 1;
	}

	.q-table thead tr:hover,
	.q-table thead tr:focus {
		background: transparent;
	}

	.q-table tbody tr:hover,
	.q-table tbody tr:focus {
		&:not(.q-tr--no-hover) {
			background: #ebf1fd36;
		}
	}

	.q-table__card--dark {
		table.q-table tr {
			&:nth-child(2n) {
				background: rgba(80, 80, 80, 0.2);
			}

			&:hover,
			&:focus {
				&:not(.q-tr--no-hover) {
					background: #3c3c3c;
				}
			}
		}
	}

	.icon-size {
		font-size: var(--icons-size-default);
	}

	.jobTitleHeading {
		width: 100%;
		display: block;
		clear: both;
		color: #444;
	}

	.textAlignLeft {
		text-align: left;
	}

	.textAlignCenter {
		text-align: center;
	}

	.textAlignRight {
		text-align: right;
	}

	.text-right {
		text-align: right;
	}

	.q-layout {
		overflow: hidden;
	}

	.q-layout__shadow:after {
		box-shadow: var(--header-shadow-default);
	}

	.q-page-container {
		overflow: hidden;
	}

	.mainContent {
		/* min-height: calc(100vh - 113px); */
		margin: 0 0 30px 0;
		padding: 20px 0 130px 0;
		/* overflow: hidden; */

		&.noNav {
			padding-top: 55px;
		}
	}

	.defaultBlockStyle {
		background: var(--colors-white);
		padding: 30px;
		vertical-align: top;
		width: 100%;
		box-sizing: border-box;
		border-radius: 5px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.03),
			0 4px 8px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.04),
			0 16px 32px rgba(0, 0, 0, 0.04), 0 32px 64px rgba(0, 0, 0, 0.04);
		clear: both;

		&.size-small {
			padding: 10px;
			position: relative;
		}

		&.noPaddingBlock {
			padding: 0;
		}
	}

	.sidebar-content {
		position: relative;
	}

	.defaultBlockStyle.noPadding {
		padding: 0;
		overflow: hidden;
	}

	/* Classes for highlighting errors/warnings in the Email Template Builder preview, both inline and modal */
	.etbErrorHighlight-a {
		background-color: red;
		color: white;
		border-radius: 6px;
		padding: 2px 4px;
		margin: 0 2px 0 2px;
	}

	.etbErrorHighlight-b {
		background-color: rgba(255, 255, 0, 0.25); /* yellow */
		color: black;
		border-radius: 6px;
		padding: 2px 4px;
		margin: 0 2px 0 2px;
	}

	.emailTemplateBuilder {

		.ProseMirror {
			border: 1px solid #ccc;
			border-radius: 4px;
			overflow-y: scroll;
			padding: 8px;
			min-height: 16em;
			max-height: calc(100vh - 510px);

			/*
			shared editor and preview styles to align with styles in the email that gets sent
			mostly care about removing misleading spacing, e.g. padding and margins that won't exist in the email
			*/
			p {
				margin: 0;
			}
		}

		.previewContent {
			/*
			shared editor and preview styles to align with styles in the email that gets sent
			mostly care about removing misleading spacing, e.g. padding and margins that won't exist in the email
			*/
			p {
				margin: 0;
			}
		}
	}

	.emailTemplateBuilder * .tiptap-menubar {
		height: auto !important;
	}

	.emailTemplateBuilderModalPreviewContent {
		border: 1px solid #aaa;
		border-radius: 6px;
		min-height: 300px;
		max-height: 70vh;
		overflow-y: auto;

		/*
		shared editor and preview styles to align with styles in the email that gets sent
		mostly care about removing misleading spacing, e.g. padding and margins that won't exist in the email
		*/
		p {
			margin: 0;
		}

		/* Ensure that images aren't stretched (by default they have 100% width) */
		img {
			width: auto;
		}
	}

	:root {
		@custom-media --small-viewport (min-width: 576px);
		@custom-media --medium-viewport (min-width: 768px);
		@custom-media --large-viewport (min-width: 992px);
		@custom-media --huge-viewport (min-width: 1200px);

		--loader-color: blue;
	}
	.ssiMyLoginsModal {
		background: #ffffff url(~@/assets/ssi/bgModal.jpg) no-repeat center 350px;
		background-size: contain;

		.q-card__section {
			padding: 20px 40px 200px;
		}

		> .q-card__section--vert.scroll {
			overflow: hidden;
		}
	}

	@media (max-width: 1024px) {
		.ssiMyLoginsModal {
			.q-card__section {
				padding-bottom: 20px;
			}

			> .q-card__section--vert.scroll {
				overflow: scroll;
			}
		}
	}

	.ssi-modal-description {
		background: white;
		padding: 10px;
		border-radius: 5px;
	}

	@media (max-width: 600px) {
		.ssiMyLoginsModal {
			background-position: center bottom;
		}
	}

	.ssiCount {
		font-size: 18px;
		border-radius: 100%;
		width: 30px;
		height: 30px;
		text-align: center;
		justify-content: center;
		background: #e0e0e0;
		color: #444;
		font-weight: 500;
	}

	.dragBound {
		height: 200px;
		opacity: 0.3;
		position: fixed;
		width: 100%;
		z-index: 9998;

		&.accel {
			height: 75px;
			z-index: 9999;
		}

		&.up {
			top: 0;
		}

		&.down {
			bottom: 0;
			transform: rotateZ(180deg);
		}
	}

	body.platform-win, body.platform-win * {
		/* firefox support - limited customizability compared to chrome */
		scrollbar-color: #a0a0a5  #f4f4f4;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			/* background-color: transparent; */
			width: 6px;
			height: 6px;
		}

		&::-webkit-scrollbar-track {
			/* background-color: transparent; */
			background-color: rgba(250, 250, 250, 0.4);
		}

		/* stylelint-disable a11y/no-display-none  */
		&::-webkit-scrollbar-button {
			display: none;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #98989d;
			/* border: 4px solid #f4f4f4; */
			width: 4px;
			height: 4px;
			border-radius: 10px;
		}

		&:hover::-webkit-scrollbar,
		&:focus::-webkit-scrollbar {
			/* Scrollbar is not an overlay, */
			/* so this causes a 1px content adjustment. */
			width: 6px;
			height: 6px;
		}

		/* &:hover::-webkit-scrollbar-track {
			background-color: rgba(250, 250, 250, 0.4);
		} */

		&:hover::-webkit-scrollbar-track,
		&:focus::-webkit-scrollbar-track {
			background-color: rgba(250, 250, 250, 0.6);
		}

		&:hover::-webkit-scrollbar-thumb,
		&:focus::-webkit-scrollbar-thumb {
			background-color: #75757a;
			/* border: 5px solid #fff; */
			width: 6px;
			height: 6px;
		}

	}

	.icon-wrapper {
		position: relative;
		float: left;
	}

	.badge {
		background: grey;
		width: auto;
		height: auto;
		margin: 0;
		border-radius: 50%;
		position: absolute;
		top: 13px;
		right: 3px;
		padding: 10px 7px;
		font-size: 0.9em;
		line-height: 1.5;
	}

	.badge.notify {
		right: 0;
		background: var(--colors-danger);
	}

	.block-dropzone {
		height: 5px;
		background: #0099f8;
		position: absolute;
		box-sizing: border-box;
		margin-top: -12px;
		animation: pulseDropzone 1s infinite;
	}

	.block-addzone {
		height: 5px;
		background: #0099f8;
		position: absolute;
		box-sizing: border-box;
		margin-top: -12px;
		margin-left: 18px;
	}

	.block-details {
		margin-bottom: 18px;
	}

	.block-drag-hover {
		background: #ddd !important;
		box-shadow: none;
		opacity: 0.6;
	}

	.roundBadge {
		border-radius: 100%;
		height: 20px;
		width: 20px;
		justify-content: center;
		font-weight: 600;
		padding: 11px;
		font-size: 0.9em;
	}

	button span {
		font-weight: 600;
	}

	.text-neutral {
		color: #8c939a;
	}

	.bg-neutral {
		background: #8c939a;
	}

	.text-white {
		color: #fff;
	}

	.bg-lightTransp {
		background: rgba(250, 250, 250, 0.8);
	}

	.bg-darkTransp {
		background: rgba(0, 0, 0, 0.7);
	}

	.q-footer {
		/* position: inherit; */
		width: 100%;
	}

	.float-clear {
		clear: both;
	}

	@keyframes pulseDropzone {
		0% {
			background-color: #0099f8;
		}

		50% {
			background-color: #0099f8;
		}

		100% {
			background-color: #0099f8;
		}
	}

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@media (min-width: 1424px) {
		.mainContent {
			width: 80rem;
			margin: 0 auto;
		}
	}

	>>> .block-wrapper {
		margin: 0;
	}

	>>> .block-content {
		z-index: 2001;
	}

	h1#pagetitle {
		font-size: 0;
		width: 1px;
		height: 1px;
		display: inline-block;
		overflow: hidden;
		position: absolute !important;
		border: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		clip: rect(1px, 1px, 1px, 1px);
	}

	.defaultAdminPanel {
		background: #fff;
		padding: 25px;
		border-radius: 10px;
	}

	/* .admin {
	.col-9 {
	height: 100vh;
	overflow-y: auto;
	}
	} */

	.adminContent {
		/* padding: 90px 40px 40px 40px; */
	}

	.adminMain {
		/* max-height: 100vh; */
		padding: 90px 40px 40px 40px;
	}

	.adminContentArea {
		background: #eaebf2;
		.tableAction {
			border: 0;
			padding: 0;
		}

		.q-table th {
			vertical-align: top;
		}
	}

	.adminSecondaryNavigation {
		background: #2c2d36;
		box-shadow: 0 0 7px hsl(0, 0%, 0%, 0.5);
		padding: 50px 0 16px 0;
		color: #fff;
		height: 100vh;
		min-height: 100%;

		.q-tabs {
			width: 100%;

			.q-tabs__content {
				overflow: auto;
			}
		}

		.q-item {
			padding: 10px 20px;
			color: hsl(0, 0%, 100%, 0.6);
			font-weight: 600;
			font-size: 0.9em;

			&.q-router-link--active,
			&.q-item--active,
			&:active,
			&:focus {
				color: #fff;
				background: var(--q-color-accent);
			}
		}

		.q-tab {
			padding: 4px 20px;
			min-height: none;
			font-size: 90%;
			font-weight: 600;
			align-content: center;
			justify-content: left;

			.q-tab__content {
				&.column {
					flex-direction: inherit;
				}
			}
		}

		.q-tab__indicator {
			visibility: hidden;
		}

		.q-tab--active {
			background: var(--q-color-accent);
		}
	}

	.quick-fade-enter-active,
	.quick-fade-leave-active {
		transition: opacity 0.05s;
	}

	.quick-fade-enter,
	.quick-fade-leave-to {
		opacity: 0;
	}

	.txtGrey-8 {
		color: var(--palette-grey-8);
	}

	.bgGrey-8 {
		background: var(--palette-grey-8);
	}

	.awaitingApprovalCard .q-card {
		opacity: 0.75;
		box-shadow: none;
		border: 2px solid var(--q-color-warning);
	}

	.q-menu {
		box-shadow: var(--shadow-element-dreamy-strong);
		border-radius: var(--roundCorners-all-sm);
	}

	/* DARK MODE */
	body.body--dark {
		transition: background 0.8s ease;
		background: var(--theme-dark-body-color-background);

		.q-dark {
			transition: background 0.8s ease;
		}

		.txtGrey-8 {
			color: var(--palette-grey-1);
		}

		.q-card {
			.txtGrey-8 {
				color: var(--palette-grey-ccc);
			}
		}

		.main.mode-full-screen {
			.sidebar {
				background: rgb(40, 43, 53);
				color: #fff;
			}
		}

		.q-toolbar {
			&.header {
				background: var(--theme-dark-header-color-background);
			}
		}

		.darkText {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: #000;
			}

			p {
				color: #333;
			}

			.joinBtn {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: #fff;
				}
			}
		}

		.tiptap .tiptap-menubar,
		.tiptap .tiptap-menububble {
			background: var(--q-color-dark);
		}

		/* eslint-disable */
		.ssiMyLoginsModal {
			background: #242526 url(~@/assets/ssi/bgModal.jpg) no-repeat center 350px !important;
			background-size: contain !important;
		}

		.footer {
			background: var(--theme-dark-footer-color-background);
			p span {
				color: var(--theme-dark-footer-color-link);
			}
			/* padding: 50px 0; */
		}
	}

	.awaitingApprovalCard {
		.q-card:hover,
		.q-card:focus {
			transform: none;
			box-shadow: none;
		}
	}

	.resultCardNoLinkCursor {
		.q-card:hover,
		.q-card:focus {
			cursor: default;
		}
	}

	.respectLineBreaks {
		white-space: pre-line;
	}

	.multiLineChip {
		height: auto;
	}

	@media (prefers-reduced-motion: reduce) {
		* {
			transition: none !important;
			animation: none !important;
		}
	}

	.platform-ios {
		/* iOS automatically zooms in on focus if input font-size is < 16px */
		input {
			font-size: 16px;
		}
	}

	.white-space-normal {
		white-space: normal;
	}

	.qLabelBreakWord {
		.q-checkbox__label {
			word-break: break-word;
		}

		.q-radio__label {
			word-break: break-word;
		}
	}

	/* == Design panel ready defaults == */
	/* These styles would ordinarily exist in components scoped to the component */
	/* They exist here instead so that CSS load order allows the design panel to override them */
	/* The design panel should be able to override some basic block styles, without the need for !important */

	/* HeroV2.vue */
	/* default styles for the block shape SHOULD ONLY EXIST HERE */
	.hero-block-root {
		box-shadow: var(--shadow-element-dreamy-strong);
		border-radius: 0 0 20px 20px;
		margin-bottom: 20px;
		margin-top: -20px;
	}

	/* CurrentUserOverview.vue */
	/* Ensures the default "full" view mode sets height at 360px, but prevents display issues if height is changed manually */
	.full-view-avatar-root {
		height: 360px;
	}

	.flex-grow {
		flex-grow: 1;
	}

	/* HeadlessSearch/Map.vue */
	/* Allows the map height to be updated */
	.block-headless-search-map-wrapper {
		height: 500px;
	}

	/** style for Show More links */
	.user-communication-show-more-link {
		opacity: 0.8;
		cursor: pointer;
		font-weight: 600;
		font-size: 90%;
		text-align: right;
		transition: 100ms;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.no-shrink {
		flex-shrink: 0;
	}

	.user-hero-overview-block-root {
		background: #353a50;
		border-radius: var(--roundCorners-bottom-md);

		&.q-card--dark {
			background: var(--q-color-dark);
		}
	}

	/** This makes the text contrast with background.
	If the background is dark, the text should be white.
	If the background is light, the text should be black.
	Note: for this to work, the most direct parent needs to have
	a background color set. If using with a quasar component like
	q-btn, you need to make sure every parent of the item that has the
	.item-color-contrast-with-background class has a background.
	See example `src/components/blocks/types/UserPost/PostSingleReactionButtons.vue`
	at line 64;
	*/
	.item-color-contrast-with-background {
		color: transparent;
		filter: invert(1) grayscale(1) contrast(9);
		-webkit-filter: invert(1) grayscale(1) contrast(9);
		background: inherit;
		background-clip: text !important;
		-webkit-background-clip: text;
		-moz-background-clip: text;
	}

	.iric {
		display: inline-flex;
		align-items: center;
	}

	/** .row.items-center */
	.ric {
		display: flex;
		align-items: center;
	}

	.rje {
		display: flex;
		justify-content: flex-end;
	}

	/** Justify-between reverse */
	.rjbrv {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
	}

	.absolute-full-size {
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.colicjc {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	/** .row.items-center.justify-center */
	.ricjc {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.block-content.block-type-entity-card {
		/**
		`important` is needed because this class is applied to `<component>` in Block.vue,
		which also receives `display: block` from .block-content (and .block-content is
		applied from a scoped style tag, so it gets a dataId attribute.)
		*/
		display: flex !important;
	}

	.ricjb {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.readable-q-tooltip {
		font-size: 130%;
		font-weight: 600;
	}

	.better-q-badge {
		font-size: 120%;
	}

	.readable-q-tooltip {
		font-size: 0.95rem;
		line-height: 1.3;
		max-width: 600px;
		text-align: center;
		display: inline-block;

		&.block {
			display: block;
		}
	}

	/** Quasar goes from 500 to 700 and skips 600, which you sometimes need. */
	.text-weight-medium-plus {
		font-weight: 600;
	}

	.headless-search-results-grey-out {
		filter: grayscale(1);
		opacity: 0.4;
		transition: opacity 0.3s ease-out;

		&:hover,
		&:focus {
			filter: unset;
			opacity: 1;
		}
	}

	/**
	In keeping with quasar's dimensions, this sometimes
	makes more sense than using margins
	*/

	.gap-sm {
		gap: 4px;
	}

	.gap-md {
		gap: 8px;
	}

	.gap-lg {
		gap: 16px;
	}

	.gap-xl {
		gap: 32px;
	}
	.dot-indicator {
		border: 2px solid white;
		border-radius: 100%;

		/* Using aspect-ratio to keep this square/circular */
		/* when just one of width or height is specified/known. */
		/* The alternative padding trick can't cope with certain situations, */
		/* e.g. when there are borders, or position: absolute is used. */
		/* https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio#browser_compatibility */
		/* https://css-tricks.com/almanac/properties/a/aspect-ratio/ */
		aspect-ratio: 1;

		/* Prevent inconsistent sizes due to flex. */
		/* https://alumin.at/i/DnNxs */
		flex-grow: 0;
		flex-shrink: 0;

	}

	.body--dark {
		.dot-indicator {
			border-color: var(--q-color-dark)
		}
	}

	.max-height-100 {
		max-height: 100%;
	}

	.gap-sm {
		gap: 4px;
	}

	.gap-md {
		gap: 8px;
	}

	.gap-lg {
		gap: 12px;
	}

	.gap-xl {
		gap: 16px;
	}

	/* Leaflet map attribution customization */
	.leaflet-bottom {
		width: 100% !important;
	}

	.leaflet-control-attribution {
		width: 100% !important;
		background: rgba(255, 255, 255, 0.6) !important;
		color: #636262 !important;
	}

	.leaflet-control-zoom-in,
	.leaflet-control-zoom-out {
		cursor: pointer;
	}

	/* Split attributions only on large screens */
	@media (min-width: 1201px) {
		.leaflet-control-attribution {
			display: flex !important;

			span {
				opacity: 0 !important;
				margin-left: auto !important;
				margin-right: auto !important;
			}
		}
	}
</style>
