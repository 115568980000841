import { addError } from '@/utils/notifications';
import { put, post, destroy } from '@/utils/api';

const state = {};

const mutations = {};

const actions = {
	async createNew({ dispatch }, definition)
	{
		try
		{
			dispatch('entityDefinitions/setLoading', true, { root: true });
			const { data } = await put('entityDefinitions', definition);

			// insert the new item into the list of defs
			dispatch('app/handleServerResponse', data, { root: true });

			return data;
		}
		catch(e)
		{
			console.error(e);
			addError(`Error creating entity definition: ${e.message}`);

			return null;
		}
		finally
		{
			dispatch('entityDefinitions/setLoading', false, { root: true });
		}
	},
	async update({ dispatch, commit }, { id, changes })
	{
		dispatch('entities/setLoading', true, { root: true });

		try
		{
			const { data } = await post(`entityDefinitions/${id}`, { changes });

			dispatch('app/handleServerResponse', data.changes, { root: true });
		}
		catch(e)
		{
			console.error(e);
			addError(e.message);
		}
		finally
		{
			dispatch('entities/setLoading', false, { root: true });
		}
	},
	async addPowerUps({ dispatch }, { definitionId, powerUpId })
	{
		dispatch('entities/setLoading', true, { root: true });

		const { data } = await put(`entityDefinitions/${definitionId}/powerUp`, { powerUpId });

		if(!data.success)
		{
			console.warn('Error updating entity definition');
		}
		else
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });
		}

		dispatch('entities/setLoading', false, { root: true });
	},
	async addFields({ dispatch }, { definitionId, fieldIds })
	{
		dispatch('entities/setLoading', true, { root: true });

		const { data } = await put(`entityDefinitions/${definitionId}/fields`, { fieldIds });

		if(!data.success)
		{
			console.warn('Error updating entity definition');
		}
		else
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });
		}

		dispatch('entities/setLoading', false, { root: true });
	},
	async addPowerUp({ dispatch }, { definitionId, powerUpId })
	{
		dispatch('addPowerUps', { definitionId, powerUpId });
	},
	async removePowerUps({ dispatch }, { definitionId, powerUpId })
	{
		dispatch('entities/setLoading', true, { root: true });

		const { data } = await destroy(`entityDefinitions/${definitionId}/powerUp`, { params: { powerUpId } });

		if(!data || !data.success)
		{
			console.warn('Error updating entity');
		}
		else
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });
		}

		dispatch('entities/setLoading', false, { root: true });
	},
	async removePowerUp({ dispatch }, { definitionId, powerUpId })
	{
		dispatch('removePowerUps', { definitionId, powerUpId });
	},
	async addField({ dispatch }, { definitionId, fieldId })
	{
		dispatch('addFields', { definitionId, fieldIds: [fieldId] });
	},
	async removeFields({ dispatch }, { definitionId, fieldIds })
	{
		dispatch('entities/setLoading', true, { root: true });

		const { data } = await destroy(`entityDefinitions/${definitionId}/fields`, { params: { fieldIds } });

		if(!data || !data.success)
		{
			console.warn('Error updating entity');
		}
		else
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });
		}

		dispatch('entities/setLoading', false, { root: true });
	},
	async removeField({ dispatch }, { definitionId, fieldId })
	{
		dispatch('removeFields', { definitionId, fieldIds: [fieldId] });
	}
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
	// modules
};
